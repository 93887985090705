<template>
	<div>
		<div class="flex header">
			<h2>停车场管理</h2>
			<div class="flex">
				<el-input
						placeholder="请输入关键信息"
						prefix-icon="el-icon-search"
						v-model="search"
						@change="getData">
				</el-input>
				<el-button @click="addPark">新增停车场</el-button>
			</div>
		</div>
		<div class="table">
			<el-table
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 248px)"
					style="width: 100%">
				<el-table-column
						type="index" align="center"
						width="80">
				</el-table-column>
				<el-table-column
						prop="parkingName" align="center" show-overflow-tooltip
						label="停车场名称">
				</el-table-column>
				<el-table-column
						prop="gateNum" align="center" show-overflow-tooltip
						label="闸口数量">
				</el-table-column>
				<el-table-column
						prop="parkingAddress" align="center" show-overflow-tooltip
						label="地点">
				</el-table-column>
				<el-table-column
						prop="stallTotal" align="center" show-overflow-tooltip
						label="车位总数">
				</el-table-column>
				<el-table-column align="center" fixed="right"
												 label="操作" width="170px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
						<el-button class="update" @click="handleDel(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<parkUpdate :showEditPark="showEditPark" :state="state" @openPark="editParkInfo" :parkInfo="parkInfo"></parkUpdate>
	</div>
</template>

<script>
import { getCarParkInfoList } from "@/api/api";

export default {
	name: "parkingMana",
	data() {
		return {
			search: "",
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			showEditPark: false,
			state: "新增",
			parkInfo: {
				parkingName: "",
				gateNum: "",
				gateList: [],
				parkingAddress: "",
				stallTotal: "",
			},
			loading:false
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true
			let params = {
				page: this.page.current,
				size: this.page.size,
				parkingName: this.search
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
				setTimeout(()=>{
					this.loading = false
				},200)
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		// 编辑弹窗
		handleEdit(index, row) {
			this.$get('/parking/parking-info/'+row.id).then(res=>{
				this.parkInfo = res.data.data
			})
			this.showEditPark = true;
			this.state = "编辑";
		},
		// 删除
		handleDel(id) {
			this.$confirm("此操作将永久删除停车场，是否继续?", "提示", { type: "warning" })
					.then(() => { // 向服务端请求删除
						this.$del('/parking/parking-info/'+id).then(res=>{
							if (res.data.code == 200){
								this.$message.success("删除成功")
								this.getData()
							}else {
								this.$message.error(res.data.msg)
							}
						})
					}).catch(() => {
				this.$message.info("已取消操作!");
			});
		},
		// 关闭弹窗
		editParkInfo(obj) {
			this.showEditPark = false;
			if (!obj.flg) {
				this.getData();
			}
		},
		// 新增
		addPark() {
			this.showEditPark = true;
			this.state = "新增";
			this.parkInfo = {
				gateNum: "",
				gateList: "",
				stallTotal: "",
				parkingAddress: "",
				parkingName: ""
			};
		}
	},
	components: {
		"parkUpdate": () => import("./parkUpdate")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
